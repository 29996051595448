import React from 'react'


export const PrivacyAndTerms = props => {
    return (
        <p className="para_4">
            {props.para1 != null ? props.para1 : 'By clicking “Next“, you give BankSocial permission to run a soft credit check and income verification so you don\'t have to fill out forms. It won\'t ever impact your credit. You also give BankSocial permission to run a soft credit check and income verification at any time while you have a BankSocial account so we can alert you to better loan options if they become available. You also agree to BankSocial\'s'}
            <a href="#"> Privacy Policy</a> and <a href="#">Terms of Service.</a>
        </p>
    )
}