import {React, useState} from 'react'
import {
  Formiz,
  FormizStep, // Import the FormizStep component
  useForm,
} from '@formiz/core'
import { isEmail } from '@formiz/validations'
import { MyField } from './MyField'
import { MyButton } from './MyButton'
import { Steps } from './Steps'
import { FormHeader } from './FormHeader'
import { PrivacyAndTerms } from './PrivacyAndTerms'
import Popup from './Popup';

import secure_lock from './../assets/svg/secure_lock.svg';
import finish from './../assets/finish.jpg';
import { SubHeader } from './SubHeader'
import { FormFooter } from './FormFooter'
import { MyRadioButton } from './MyRadioButton'
//import { MyLogin } from './MyLogin'


// export default class MyForm extends Component {
export const MyForm = () => {
  
  const myForm = useForm()

  const urlParams = new URLSearchParams(window.location.search);
  const loggedIn = urlParams.get('user');
  
  const handleSubmit = async (values) => {
    let user_id = '';
    let loan_type = values.help_with_business != null?'Business': values.help_with_personal!= null?'Personal': '';
    let home_loan_type = values.loan_type_purchase!= null?'Purchase': values.loan_type_refinance!= null?'Refinance': '';
    let business_loan_type = values.business_type_new_construction!= null?'New Construction':values.business_type_fix_flip!= null?'Fix & Flip':values.business_type_asset_backed!= null?'Asset Backed':'';
    let is_crypto = values.is_crypto_yes!= null ? 1: values.is_crypto_no!= null ? 0:'';
    let cash_out = values.cash_out_yes!= null?1:values.cash_out_no!= null?0:'';
    let first_name = values.f_name;
    let last_name = values.l_name;
    let street_address = values.street_address;
    let apt_unit = values.aptunit;
    let city = values.city;
    let state = values.state;
    let zip = values.zipcode;
    let email = values.email;
    let street_address_loan = values.street_address_loan!=null?values.street_address_loan:'';
    let aptunit_loan = values.aptunit_loan!=null?values.aptunit_loan:'';
    let city_loan = values.city_loan!=null?values.city_loan:'';
    let state_loan = values.state_loan!=null?values.state_loan:'';
    let zipcode_loan = values.zipcode_loan!=null?values.zipcode_loan:'';
    let purchase_price_loan = values.purchase_price_loan!=null?values.purchase_price_loan:'';
    let asset_description = values.asset_description;

    console.log(
      "user_id: ''\n"+
      "loan_type: "+loan_type+"\n"+ 
      "home_loan_type:"+ home_loan_type +"\n"+
      "business_loan_type:"+business_loan_type+"\n"+
      "is_crypto:"+ is_crypto +"\n"+
      "cash_out:"+cash_out+"\n"+
      "first_name:"+first_name +"\n"+
      "last_name:"+ last_name +"\n"+
      "street_address:"+street_address+"\n"+
      "apt_unit:"+apt_unit+"\n"+
      "city:"+city+"\n"+
      "state:"+state+"\n"+
      "zip:"+zip+"\n"+
      "email:"+email+"\n"+
      "street_address_loan:"+street_address_loan+"\n"+
      "apt_unit_loan:"+aptunit_loan+"\n"+
      "city_loan:"+city_loan+"\n"+
      "state_loan:"+state_loan+"\n"+
      "zip_loan:"+zipcode_loan+"\n"+
      "purchase_price_loan:" + purchase_price_loan,
      "street_address_loan:" + street_address_loan,
      "asset_description:" + asset_description,
      "apt_unit_loan:" + aptunit_loan,
      "city_loan:" + city_loan,
      "state_loan:" + state_loan,
      "zip_loan:" + zipcode_loan,
      "purchase_price_loan:" + purchase_price_loan
           )
      

    // (A) GET FORM DATA
    var data = new FormData();
    data.append("user_id",'' );
    data.append("loan_type",loan_type );
    data.append("home_loan_type",home_loan_type );
    data.append("business_loan_type",business_loan_type);
    data.append("is_crypto",is_crypto );
    data.append("cash_out",cash_out);
    data.append("first_name",first_name );
    data.append("last_name",last_name );
    data.append("street_address",street_address);
    data.append("apt_unit",apt_unit);
    data.append("city",city);
    data.append("state",state);
    data.append("zip",zip);
    data.append("email",email )
    data.append("street_address_loan",street_address_loan )
    data.append("apt_unit_loan",aptunit_loan )
    data.append("city_loan",city_loan )
    data.append("state_loan",state_loan )
    data.append("zip_loan",zipcode_loan )
    data.append("purchase_price_loan",purchase_price_loan )
    data.append("asset_description",asset_description );
    
   
    // (B) INIT FETCH POST
    fetch(process.env.REACT_APP_API_URL+"/api/loan-details", {
      method: "POST",
      headers:{
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: data
    })
   
    // (C) RETURN SERVER RESPONSE AS TEXT
    .then((result) => {
      if (result.status != 200) { throw new Error("Bad Server Response"); }
      return result.text();
    })
   
    // (D) SERVER RESPONSE
    .then((response) => {
      console.log(response);
    })
   
    // (E) HANDLE ERRORS - OPTIONAL
    .catch((error) => { console.log(error); });
   
    // (F) PREVENT FORM SUBMIT
    return false;

  };
 

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
      setIsOpen(!isOpen);
  }

  return (
    <Formiz connect={myForm} onValidSubmit={handleSubmit} >
      <form noValidate onSubmit={myForm.submitStep} >

        <FormizStep name="step1">
          <div>
              <Steps currentStep={'1'}/>
              <div className="form_section">
                <SubHeader question="What type of loan do you need?"/>
                  <div className='gravity_center flex_column'>
                  
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() =>{ myForm.goToStep('step2a')}} label="Personal" name="help_with_personal" value="personal"/>
                    <br/>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step2b')} label="Business" name="help_with_business" value="business"/>
                    <br/>
                    
                   {/* <MyLogin loginLabel={ loggedIn }/> */}

                    <MyButton />
                  </div>
                  <FormFooter/>
              </div>
          </div>
        </FormizStep>

        <FormizStep name="step2a" >
          <div>
              <Steps currentStep={'2'}/>
              <div className="form_section">
                <SubHeader question="What type of Home Loan?"/>
                  <div className='gravity_center flex_column'>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step3')} label="Purchase" name="loan_type_purchase" value="purchase"/>
                    <br/>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step5')} label="Refinance" name="loan_type_refinance" value="refinance"/>
                    <br/>
                    {/* <MyLogin loginLabel={ loggedIn }/> */}

                    <MyButton />
                  </div>
                  <FormFooter/>
              </div>
          </div>
        </FormizStep>
        <FormizStep name="step2b" >
          <div>
              <Steps currentStep={'2'}/>
              <div className="form_section">
                <SubHeader question="What type of Business Loan?"/>
                <br/>
                  <div className='gravity_center flex_column'>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step3')} label="New Construction" name="business_type_new_construction" value="New Construction"/>
                    <br/>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step3')} label="Fix and Flip" name="business_type_fix_flip" value="Fix and Flip"/>
                    <br/>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step3')} label="Asset Backed" name="business_type_asset_backed" value="Asset Backed"/>
                    <br/>
                    {/* <MyLogin loginLabel={ loggedIn }/> */}

                    <MyButton />
                  </div>
                  <FormFooter/>
              </div>
          </div>
        </FormizStep>
        <FormizStep name="step3" >
          <div>
              <Steps currentStep={'2'}/>
              <div className="form_section">
                <SubHeader question="Using cryptocurrency for this loan?"/>
                  <div className='gravity_center flex_column'>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step4')} label="Yes" name="is_crypto_yes" value="yes"/>
                    <br/>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step5')} label="No" name="is_crypto_no" value="no"/>
                    <br/>
                    {/* <MyLogin loginLabel={ loggedIn }/> */}

                    <MyButton />
                  </div>
                  <FormFooter/>
              </div>
          </div>
        </FormizStep>
        <FormizStep name="step4" >
          <div>
              <Steps currentStep={'2'}/>
              <div className="form_section">
                <SubHeader question="Do you want to Cash Out?"/>
                  <div className='gravity_center flex_column'>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step5')} label="No, Keep my Crypto" name="cash_out_no" value="No"/>
                    <br/>
                    <MyRadioButton buttonClass="btn blue radio_button" clickAction={() => myForm.goToStep('step5')} label="Yes" name="cash_out_yes" value="Yes"/>
                    <br/>
                    {/* <MyLogin loginLabel={ loggedIn }/> */}

                    <MyButton />
                  </div>
                  <FormFooter/>
              </div>
          </div>
        </FormizStep>
        
        <FormizStep name="step5" >
          <div>
              <Steps currentStep={'3'}/>
              <FormHeader heading="Welcome" step="5" business={ myForm.values.help_with_business } refi={myForm.values.loan_type_refinance} para1="Let's start by getting some info about you!" para2="Borrowers who use BankSocial's verified pre-approval letter are 3 times more likely to get their dream home."/>
              <div className="form_section">
                  <div className="form_content" >
                      <p className="para_3">
                          Enter your legal name and address as they appear on your ID.
                      </p>
                      <br />
                      <MyField type="email" name="email" label="Email *" placeholder="Email" required="Email is required" validations={[
    {
      rule: isEmail(),
      message: 'This is not a valid email',
    },
  ]}/>
                      <MyField name="f_name" label="First name *" placeholder="First name" required="First name is required" />
                      <MyField name="l_name" label="Last name *" placeholder="Last name" required="Last name is required" />
                      <div className="form_input_group">
                        <MyField flexClass="flex_5" name="street_address" label="Street Address *" placeholder="Street Address" required="Street Address is required" />
                        <MyField flexClass="flex_2" name="aptunit" label="Apt, Unit" placeholder="Apt, Unit" />
                      </div>
                      <div className="form_input_group">
                        <MyField flexClass="flex_3" name="city" label="City *" placeholder="City" required="City is required" />
                        <MyField flexClass="flex_1" name="state" label="State *" placeholder="State" required="State is required" />
                        <MyField flexClass="flex_2" name="zipcode" label="Zip code *" placeholder="Zip code" required="Zip code is required" />
                      </div>
                      <br/><br/><br/>
                      <div className='full_width gravity_center'>
                        <button type="button" className="btn_submit" disabled={!myForm.isStepValid} onClick={myForm.nextStep}>Next</button>
                      </div>
                      <div className='full_width gravity_center'>
                        <MyButton />
                      </div>
                      <PrivacyAndTerms/>
                  </div>
                  <p className="para_5">
                      <img src={secure_lock} height="30px"/>All of your information is 256-bit encrypted and stored securely.
                  </p>
              </div>
          </div>
        </FormizStep>
        <FormizStep name="step6" >
          <div>
              <Steps currentStep={'4'}/>
              <FormHeader step="6" business={ myForm.values.help_with_business } refi={myForm.values.loan_type_refinance} heading="Need funds for something specific?" para1="Let us know if you have a property in mind." para2="By giving us the property info we can pre verify everything and give you a stronger approval or pre-approval letter.  You can also just let us know how much your needing so we can approve you for that amount."/>
              <div className="form_section">
                  <div className="form_content" >
                      <p className="para_3">
                        Give us an idea of how much your looking for. 
                      </p>
                      <br />
                      <MyField name="purchase_price_loan" label={myForm.values.loan_type_refinance == true ?  "Refinance Amount" : "Loan Amount"} placeholder="$XXX,XXX" required="Loan amount is required"/>
                      <br /><br />
                      <p className="para_3">
                        { myForm.values.loan_type_refinance ?
                        "Tell us about the property you're wanting to refinance." : 
                        
                          (myForm.values.business_type_asset_backed ?
                          "Please describe the asset you are looking to purchase." : "If you don't have a property in mind you can skip this step.")
                        }
                      </p>
                      <br/>
                      {myForm.values.business_type_asset_backed ? (
                        <div className="form_input_group">
                          <MyField flexClass="flex_6" name="asset_description" label="Asset Description" placeholder="Asset Description"/>
                        </div>
                      ) : (
                        null 
                      )}
                      {!myForm.values.business_type_asset_backed && (
                        <div>
                          <div className="form_input_group">
                            <MyField flexClass="flex_5" name="street_address_loan" label="Street Address" placeholder="Street Address"/>
                            <MyField flexClass="flex_2" name="aptunit_loan" label="Apt, Unit" placeholder="Apt, Unit"/>
                          </div>
                          <div className="form_input_group">
                           <MyField flexClass="flex_3" name="city_loan" label="City" placeholder="City"/>
                           <MyField flexClass="flex_1" name="state_loan" label="State" placeholder="State"/>
                           <MyField flexClass="flex_2" name="zipcode_loan" label="Zip code" placeholder="Zip code"/>
                          </div>
                        </div>
                      )}
                      <br/><br/><br/>
                      <div className='full_width gravity_center'>
                        <button type="submit" className="btn_submit" disabled={!myForm.isStepValid} onClick={() => { myForm.submit();}}>Next</button>
                      </div>
                      <div className='full_width gravity_center mt-20'>
                        <MyButton />
                      </div>
                      <PrivacyAndTerms/>
                  </div>
                  <p className="para_5">
                      <img src={secure_lock} height="30px"/>All of your information is 256-bit encrypted and stored securely.
                  </p>
              </div>
          </div>
        </FormizStep>
        
        <FormizStep name="step7" >
          <div>
              <Steps currentStep={'5'}/>
              <FormHeader lineClass="display_none" logosClass="display_none" heading="Congratulations!" para1="Blockchain robots are working on your application." para2="Your application is now in process and we will be getting back to you soon with next steps."/>
              <div className="form_section">
                  <div className="form_content _70_width" >
                      
                      <img className='full_width' src={finish} alt="Finish Image"/>
                      <div className="text_center">
                      {/* <a href="#" className='text-blue'>Sign in to your account</a> | */}
                      {/* <MyLogin loginLabel={ loggedIn }/> */}

                      </div>
                      {/* <br/><br/><br/> */}
                      {/* <div className="full_width justify_center">
                          <button type="submit" className="btn green" onClick={togglePopup} disabled={!myForm.isStepValid}>Finish</button>
                      </div> */}
                  </div>
                  <p className="para_5">
                      <img src={secure_lock} height="30px"/>All of your information is 256-bit encrypted and stored securely.
                  </p>
              </div>
          </div>
        </FormizStep>
        {isOpen && <Popup
            content={<>
                <h2>Congratz!</h2>
                <p>
                {JSON.stringify(myForm.values)}
                </p>
                <a className="btn_filled" onClick={togglePopup}>dismiss</a>
            </>}
            handleClose={togglePopup}
            />}
      </form>
    </Formiz>
  )
}
