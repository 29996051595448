import React from 'react'
import { useField } from '@formiz/core'

export const MyField = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
  } = useField(props)
  const { label, type, required, placeholder, flexClass } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  return (
    <div className={`form_field ${flexClass} ${(showError) ? 'is-error' : ''}`}>
      {/* <label htmlFor={id}> { label } {!!required && ' *'} </label> */}
      <label htmlFor={id}> { label } {!!required} </label>
      <input
        id={id}
        type={type || 'text'}
        value={value ?? ''}
        placeholder={placeholder ?? ''}
        onChange={e => setValue(e.target.value)}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}/>
      {showError && ( <div id={`${id}-error`} className="required"> { errorMessage } </div> )}
    </div>
  )
}