import React from 'react'
import equal_housing from './../assets/svg/equal_housing.svg';
import trustpilot_v2 from './../assets/svg/trustpilot_v2.svg';

export const FormFooter = props => {
    return (
        <div className="justify_center">
            <img src={equal_housing} alt="equal housing" height="22"/>
            <img src={trustpilot_v2} alt="trustpilot v2" height="22" style={{marginLeft: 15+ 'px'}}/>
        </div>
    )
}