import React from 'react'
import banksocial_logo_dark from './../assets/banksocial_logo_dark.png';

export const SubHeader = props => {
    return (
        <div className="flex_column align_center">
            <div className="justify_center align_center">
                <img src={banksocial_logo_dark} alt="B symbol" height={40}/>
            </div>
            <br/>
            <h2 className='text_dark'>{props.question != null ? props.question : 'Question?'}</h2>
        </div>
    )
}