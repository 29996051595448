import React from 'react'
export const Steps = props => {
    return (
          
      <div>
            <div className="steps_container">
                  <div className="flex_1 flex_column align_center">
                      <div className={props.currentStep == 1 ? 'step_progress active' : 'step_progress'}></div>
                      <div className="align_center mt-20 sm_display_none">
                          <span className="step_count">1</span>
                          <label>Begin</label>
                      </div>
                  </div>
                  <div className="flex_1 flex_column align_center">
                      <div className={props.currentStep == 2 ? 'step_progress active' : 'step_progress'}></div>
                      <div className="align_center mt-20 sm_display_none">
                          <span className="step_count">2</span>
                          <label>Loan Type</label>
                      </div>
                  </div>
                  <div className="flex_1 flex_column align_center">
                      <div className={props.currentStep == 3 ? 'step_progress active' : 'step_progress'}></div>
                      <div className="align_center mt-20 sm_display_none">
                          <span className="step_count">3</span>
                          <label>My Info</label>
                      </div>
                  </div>
                  <div className="flex_1 flex_column align_center">
                      <div className={props.currentStep == 4 ? 'step_progress active' : 'step_progress'}></div>
                      <div className="align_center mt-20 sm_display_none">
                          <span className="step_count">4</span>
                          <label>Property</label>
                      </div>
                  </div>
                  <div className="flex_1 flex_column align_center">
                      <div className={props.currentStep == 5 ? 'step_progress active' : 'step_progress'}></div>
                      <div className="align_center mt-20 sm_display_none">
                          <span className="step_count">5</span>
                          <label>Finished</label>
                      </div>
                  </div>
              </div>
              <div className="separator_line display_none sm_display_block"></div>
        </div>
    )
}

export default Steps
