import React from 'react';
import './common.css';
import './App.css';
import {MyForm} from './components/MyForm';

function App() {
  return (
    <div className="App">
      <MyForm/>
    </div>
  );
}

export default App;
