import React from 'react'
import bbb_accredited from './../assets/svg/bbb_accredited.svg';
import equal_housing from './../assets/svg/equal_housing.svg';
import trustpilot_rating_4 from './../assets/svg/trustpilot_rating_4.svg';
import trustpilot_v2 from './../assets/svg/trustpilot_v2.svg';

export const FormHeader = props => {

    let heading = null;
    let para1 = null;
    let para2 = null;

    if( props.step == '5' && props.refi == true ) {
        para2 = 'Borrowers who refinance wth BankSocial are able to close fast and, on average, get a higher cashout than traditional options.';

    } else if ( props.step == '6' && props.refi == true ) {
        heading = "Let's start your refinance!";
        para1 = 'Tell us about your property.';
        para2 = 'By giving us the info on the property you want to refinance we\'re able to move faster on your loan docs and evaluate the best loan options before reaching out to you.';
    } else if ( props.step == '5' && props.business == true ) {
        console.log(props.business)
        para2 = 'BankSocial hardmoney loans are able to close fast cause our pre-approvals are as good as cash.  Close in as little as 1 day.';
    } else if ( props.step == '6' && props.business == true ) {
        para2 = 'Since our hard money loans can be closed in as little as a day, its better to have more info to be able to move faster on your loan docs and evaluate the best loan options before reaching out to you.';
    } 


    return (
        <div className="header_section">
            <div className="header_content">
                <h1 className="heading_1">{heading != null ? heading : props.heading}</h1>
                <br/>
                <p className="para_1">{para1 != null ? para1 : props.para1}</p>
                <p className="para_2"> {para2 != null ? para2 : props.para2}</p>
                <br/>
                <div className={`separator_line ${props.lineClass || ''}`}></div>
                <div className={`full-width justify_center align_center margin_each_x10 logos ${props.logosClass || ''}`}>
                    <img src={trustpilot_v2} height="22px"/>
                    <img src={trustpilot_rating_4} height="18px"/>
                    <img src={equal_housing} height="22px"/>
                </div>
            </div>
        </div>
    )
}