import React from 'react'
import { useField } from '@formiz/core'

export const MyRadioButton = (props) => {
  
  

  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    // setChecked,
    value,
    checked,
  } = useField(props)
  const { buttonClass,type, label, clickAction} = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)


  return (
    <label className={buttonClass} htmlFor={id}>{label}
      <input 
        id={id}
        type={type || 'checkbox'}
        value={value ?? ''}
        // onChange={e => {setValue(e.target.checked); clickAction(); console.log(e.target.checked);}}
        onChange={e => {setValue(e.target.checked); clickAction();}}
        />
    </label>
  )
}